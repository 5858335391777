import { defineStore } from "pinia";
import { ref } from "vue";
import {
  findManyProductsByIds,
  findProduct,
  getAllFutureReleaseProducts,
  getProductPreSalePercentage,
  getProducts,
  getProductsStocksByIds,
  getReleaseProducts,
  getSuggestions as getSuggestionsHttp,
} from "../api/products";
import { useUnAutorize } from "~/composables/unAuthHandler";
import { useCartStore } from "./CartStore";
import { useUserStore } from "./UserStore";
import { getRedis, setRedis } from "../api/redis";
import { useConstants } from "../constants";
import { CLD_ENV, NODE_ENV } from "~~/constants/env";
import { useURLs } from "~~/constants/api.urls";
import nuxtStorage from "nuxt-storage";

export const useProductStore = defineStore("product", () => {
  const cartStore = useCartStore();
  const userStore = useUserStore();
  const cldUser = computed(() => userStore.cldUser);
  const products = ref([]);
  const associationsProducts = ref([]);
  const recentlyViewedIds = ref([]);
  const recentlyViewed = ref([]);
  const cartProducts = ref({});
  const promoProducts = ref([]);
  const welcomProducts = ref({});
  const product = ref({});
  const suggestions = ref([]);
  const preSalePercentage = ref(0);
  const productsPreSalePercentage = ref({});
  const backOrders = ref({});
  const attributeFilters = ref({});
  const productsCount = ref(0);
  const pageCount = ref(1);
  const timeoutId = ref();
  const stocksKV = ref({});
  const loading = ref(true);
  const productsDistinctCategory = ref({1:{},2:{},3:{}});
  const addViewedItem = async (productId) => {
    const index = recentlyViewedIds.value.indexOf(productId);
    if (index !== -1) {
      // Remove the product from its current position
      recentlyViewedIds.value.splice(index, 1);
    } else if (recentlyViewedIds.value.length >= 10) {
      // If already 10 products, remove the oldest one (from the end)
      recentlyViewedIds.value.pop();
    }
    // Add the productId to the beginning of the viewed products list
    recentlyViewedIds.value.push(productId);
  };
  const getCartProducts = async ({ selectedLanguage, ids }) => {
    if (ids.length) {
      const data = await findManyProductsByIds({
        ids: ids,
        selectedLanguage,
        params: "productsForCart",
      });
      if (data.value && data.value.products && data.value.products.length) {
        cartProducts.value = {
          ...Object.assign(
            {},
            ...data.value.products.map((x) => {
              return {
                [x.Identifier]: { ...x },
              };
            })
          ),
        };
      }
    }
  };
  const fetchViewedProducts = ({ selectedLanguage, id }) => {
    setTimeout(async () => {
      const ids = recentlyViewedIds.value.filter((x) => x !== id);
      if (ids.length) {
        const data = await findManyProductsByIds({
          ids: ids,
          selectedLanguage,
          params: "RecentlyViewedProducts",
        });

        if (data.value && data.value.products && data.value.products.length) {
          recentlyViewed.value = data.value.products;
          // if (key) {
          //   const key = cldUser.value?.Id
          //     ? `recently_viewed_products_ip${cldUser.value?.Id}`
          //     : null;
          //   if (key) {
          //     getRedis(key).then((fromCache) => {
          //       if (fromCache && fromCache?.length) {
          //         recentlyViewed.value = fromCache;
          //       }
          //     });
          //   }
          //   setRedis({
          //     key: key,
          //     value: data.value.products,
          //   }).then();
          // }
        }
      }
    }, 1000);
  };
  const fetchReleasedProducts = async ({
    releaseFilters,
    startRowIndex,
    range,
    selectedLanguage,
    sortOrder,
  }) => {
    loading.value = true;
    const {
      products: _products,
      success: isSuccess,
      attributeFilters: _attributeFilters,
      productsCount: _productsCount,
      pageCount: _pageCount,
    } = await getReleaseProducts({
      releaseFilters,
      startRowIndex,
      range,
      selectedLanguage,
      sortOrder,
    });
    console.log("data for released products", _products);

    loading.value = false;
    if (isSuccess) {
      products.value = _products;
      attributeFilters.value = _attributeFilters;
      productsCount.value = _productsCount;
      pageCount.value = _pageCount;
      handleBackOrders();
    }
  };
  const checkAggregations = async (data, range) => {
    if (data.value) {
      const aggregations = {
        ...data.value.aggregations,
        in_stock: {
          buckets: [
            {
              key: 1,
              key_as_string: "true",
              doc_count: " ", // "> 0"
              custome_boolean_filter: true,
            },
          ],
        },
        in_reserved: {
          buckets: [
            {
              key: 1,
              key_as_string: "true",
              doc_count: " ", // "> 0"
              custome_boolean_filter: true,
              auth: true,
            },
          ],
        },
      };
      attributeFilters.value = aggregations;
      // products.value = data.value?.productsN;
      productsCount.value = data.value.count;
      pageCount.value = Math.ceil(productsCount.value / range);
      loading.value = false;
      handleBackOrders();
    }
  };
  const fetchAllFutureReleaseProducts = async ({
    releaseFilters,
    startRowIndex,
    range,
    selectedLanguage,
    sortOrder,
  }) => {
    loading.value = true;
    const {
      products: _products,
      success: isSuccess,
      attributeFilters: _attributeFilters,
      productsCount: _productsCount,
      pageCount: _pageCount,
    } = await getAllFutureReleaseProducts({
      releaseFilters,
      startRowIndex,
      range,
      selectedLanguage,
      sortOrder,
    });
    console.log("data for released products", _products);

    loading.value = false;
    if (isSuccess) {
      products.value = _products;
      attributeFilters.value = _attributeFilters;
      productsCount.value = _productsCount;
      pageCount.value = _pageCount;
      handleBackOrders();
    }
  };

  const fetchProducts = async ({
    sortOrder,
    filterInput,
    range,
    startRowIndex,
    selectedFilters,
    selectedLanguage,
    priceMin,
    priceMax,
    searchByMultiIds,
  }) => {
    const url = useURLs[NODE_ENV].PRODUCT_SEARCH_URL;
    loading.value = true;
    const payload = JSON.stringify({
      prefix: filterInput,
      range: range,
      startRowIndex: startRowIndex,
      filters: selectedFilters,
      selectedLanguage,
      sortOrder: sortOrder,
      searchByMultiIds,
      ...{ ...((priceMin > 1 || priceMax < 9999) && { priceMin, priceMax }) },
    });
    const token = nuxtStorage.localStorage?.getData("token");
    const { data, error } = await useFetch(url, {
      key: `${payload}`,
      method: "POST",
      body: payload,
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
        ...{ ...(token && { ...{ Authorization: `Bearer  ${token}` } }) },
      },
      retry: 1,
      pick: ["productsN", "count", "aggregations", "Categorylabels"],
    });
    if (data.value && data.value?.productsN) {
      products.value = data.value.productsN;
    }
    if(data.value?.Categorylabels){
      productsDistinctCategory.value = data.value.Categorylabels;
    }
    checkAggregations(data, range);
    return data.value;
  };
  const checkAssociationsInBackground = async () => {
    associationsProducts.value = [];

    if (
      product.value &&
      product.value?.associations &&
      product.value.associations.X_SELL.Products &&
      product.value.associations.X_SELL.Products.length
    ) {
      const associationsxSellIds = product.value.associations.X_SELL.Products;
      findManyProductsByIds({
        ids: associationsxSellIds,
        selectedLanguage,
        params: "associationsProducts",
      }).then((data) => {
        if (data.value && data.value.products && data.value.products.length) {
          associationsProducts.value = data.value.products;
        }
      });
    }
  };
  const fetchProduct = async ({ id, selectedLanguage }) => {
    try {
      product.value = {};

      const url = `${useURLs[NODE_ENV].PRODUCT_FIND_URL}?id=${id}`;
      const payload = JSON.stringify({
        ids: id,
        selectedLanguage,
      });
      const token = nuxtStorage?.localStorage?.getData("token");
      const { data } = await useFetch(url, {
        key: `${payload}`,
        method: "POST",
        body: payload,
        headers: {
          client_id: useURLs[NODE_ENV].CLIENT_ID,
          client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
          "Content-Type": "application/json",
          ...{ ...(token && { ...{ Authorization: `Bearer  ${token}` } }) },
        },
        retry: 2,
      });
      if (data.value && data.value["product"]) {
        product.value = data.value["product"];
      }
      checkAssociationsInBackground();
      return data.value;
    } catch (error) {
      console.error("Error fetching product info", error);
    }
    return product.value;
  };
  const fetchWelcomeProductsWithFilters = async ({
    selectedLanguage,
    range = 15,
    startRowIndex = 1,
    categories = [],
    attribute,
    key,
  }) => {
    const { products: _products, success } = await getProducts({
      sortOrder: [
        { "Values.stock.Data": { order: "desc", unmapped_type: "long" } },
      ],
      filterInput: "",
      range,
      startRowIndex,
      selectedFilters: {
        categories,
        attribute,
      },
      selectedLanguage,
    });
    loading.value = false;

    if (success) {
      welcomProducts.value[key] = _products;

      handleBackOrders();
    }
  };
  const fetchPromoProducts = async ({
    selectedLanguage,
    range = 15,
    startRowIndex = 1,
  }) => {
    const { products: _promoProducts, success } = await getProducts({
      sortOrder: [
        { "Values.stock.Data": { order: "desc", unmapped_type: "long" } },
      ],
      filterInput: "",
      range,
      startRowIndex,
      selectedFilters: {
        categories: [],
        attribute: {
          price_promo: {
            "price promo": true,
          },
        },
      },
      selectedLanguage,
    });
    loading.value = false;
    promoProducts.value = [];

    if (success) {
      promoProducts.value = _promoProducts;

      handleBackOrders();
    }
  };
  const getBackOrders = async ({ skus, token }) => {
    const url = useURLs[CLD_ENV].CLD_BACK_ORDERS;
    const { data } = await useFetch(url, {
      method: "POST",
      body: JSON.stringify({
        Skus: skus,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });

    return data;
  };
  const getBackOrdersCldWebApi = async ({ skus }) => {
    const token = nuxtStorage.localStorage.getData("token");
    if (!skus.length || !token) return;

    const newBackOrders = await getBackOrders({
      skus,
      token,
    });
    if (newBackOrders.value) {
      backOrders.value = {
        ...backOrders.value,
        ...newBackOrders.value,
      };
    }
  };
  const handleBackOrders = async () => {
    if (products.value.length) {
      clearTimeout(timeoutId.value);
      timeoutId.value = setTimeout(async () => {
        // filtering to make sure that backOrder is not already caculated
        const skus = products.value
          .filter((e) => !backOrders.value[e.Identifier])
          .map((x) => x.Identifier);

        await getBackOrdersCldWebApi({ skus });
      }, 3000);
    }
  };
  const refillWelcomeProductsFromRedis = async () => {
    const fromCache = await getRedis("welcomProducts");
    if (fromCache) {
      welcomProducts.value = fromCache;
    }
  };
  const setWelcomeProductsToRedis = async () => {
    setRedis({
      key: "welcomProducts",
      value: welcomProducts.value,
    }).then(() => console.log("welcomProducts settled in cache"));
  };
  const getSuggestions = async (keyword) => {
    if (keyword.length < 3) {
      return;
    }
    const chainId = cartStore.sessionData["ChainId"];
    const data = await getSuggestionsHttp({ keyword, chainId });
    suggestions.value = data;
  };
  const resetProducts = () => {
    products.value = [];
  };

  const getProductPreSalePercentageCld = async (id) => {
    const fromCache = await getRedis(
      useConstants["redis_keys"].PRESALE_PERCENTAGE
    );

    if (fromCache && Object.keys(fromCache).length && fromCache[id]) {
      preSalePercentage.value = fromCache[id];
      return;
    }
    const data = await getProductPreSalePercentage(id);
    if (data <= 0) {
      return;
    }
    // console.log("data for getProductPreSalePercentageCld " + id, data);
    preSalePercentage.value = data.value.data;
    productsPreSalePercentage.value = {
      ...productsPreSalePercentage.value,
      [id]: data.value.data,
    };
    setRedis({
      key: useConstants["redis_keys"].PRESALE_PERCENTAGE,
      value: productsPreSalePercentage.value,
    }).then(() => console.log("PRESALE_PERCENTAGE settled in cache"));
  };

  const fetchProductsStocksByIds = async (ids) => {
    const data = await getProductsStocksByIds({ ids });
    console.log("data", data);
    stocksKV.value = data.ItemsKV;
  };
  const setProductDetails = (v) => {
    product.value = v;
  };
  return {
    backOrders,
    products,
    productsDistinctCategory,
    loading,
    stocksKV,
    product,
    recentlyViewed,
    associationsProducts,
    suggestions,
    attributeFilters,
    productsCount,
    pageCount,
    preSalePercentage,
    promoProducts,
    welcomProducts,
    fetchProducts,
    fetchProduct,
    fetchReleasedProducts,
    fetchAllFutureReleaseProducts,
    fetchViewedProducts,
    handleBackOrders,
    resetProducts,
    getSuggestions,
    getBackOrdersCldWebApi,
    getProductPreSalePercentageCld,
    fetchProductsStocksByIds,
    fetchPromoProducts,
    addViewedItem,
    getCartProducts,
    cartProducts,
    setProductDetails,
    fetchWelcomeProductsWithFilters,
    refillWelcomeProductsFromRedis,
    setWelcomeProductsToRedis,
  };
});
